<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Completes Series B Financing to Advance Pipeline into the Clinic and Expand Immuno-Oncology Drug Discovery Platform
      </div>
      <div class="time">
        Oct 14, 2021
      </div>
      <div class="content">
        <p>
          GV20 Therapeutics, a biotechnology company with unique expertise in novel target identification and antibody drug discovery in immuno-oncology, today announced the completion of its Series B financing. This round of financing was led by Coatue Management, with participation from existing investors IDG Capital, 3W Investment, Watson Capital, Linear Capital, and additional investors.
        </p>
        <div>
          <p>
            <img width="100%" src="../assets/cded33dade06acdd68e3488d74d9423.jpg" alt="">
          </p>
        </div>
        <p>
          “GV20” is the acupuncture point on top of the head, implying the company’s goal of finding cancer vulnerabilities. The company was cofounded in 2016 by Shirley Liu, a Professor at Dana-Farber Cancer Institute and Harvard T.H. Chan School of Public Health, and Ted Xiao, a former postdoctoral fellow at Dana-Farber Cancer Institute and Harvard Medical School. GV20 Therapeutics applies novel genomics and AI techniques to accelerate antibody drug discovery and development for cancer immunotherapy. Over the last few years, GV20 has built a proprietary genetic screening and functional genomics platform for target identification. In addition, the company has established a proprietary AI platform to de novo design fully-human functional antibodies with favorable developability profiles, allowing time and cost-efficient antibody drug discovery. Combining the power of these two technology platforms, GV20 has built a solid antibody drug portfolio against novel and challenging targets. “It is a very exciting time with high-throughput genomics, AI and cancer immunology converging on GV20 Therapeutics. We believe our platforms have demonstrated unique capabilities to identify potent antibodies against multiple targets. We are well-poised to crack the antibody drug discovery code and bring effective immunotherapy drugs to cancer patients,” said Shirley Liu.
        </p>
        <p>
          Proceeds from this financing will be predominantly used to advance GV20 Therapeutics’s lead antibody program into the clinic in the coming year while expanding its drug discovery portfolio against additional immune-oncology targets. The lead antibody program is directed against a novel immunotherapy target where internal pre-clinical studies have demonstrated remarkable in vivo efficacy as a single-agent as well as in combination with established immunotherapy. GV20 intends to develop select additional clinical antibody candidates through external biopharmaceutical collaborations. Kai Jiang, Partner at Coatue, commented “The progress made by GV20 demonstrates the company’s ability to rapidly identify novel targets and fully-human functionally efficacious antibodies, and initiate development to anticipated clinical trials. GV20 is poised to become a technology leader in antibody discovery research and immunotherapy drug development in the coming years.”
        </p>
        <div class="item">
          <div class="font-weight-bold">
            About Coatue
          </div>
          <p>
            Coatue is one of the largest technology investment platforms in the world with over $40 billion in assets under management. Coatue’s dedicated team of engineers and data scientists work closely with investment professionals to add value to founders and executive teams in our portfolio. With venture, growth and public funds, Coatue backs entrepreneurs from around the globe and at every stage of growth. Some of Coatue’s private investments in healthcare includes Caris Life Sciences, Singular Genomics, other cases include Airtable, Ant Financial, Anaplan, ByteDance, Chime, Databricks, DoorDash, Instacart, Meituan, Snap, Snowflake and Spotify.
          </p>
        </div>
        <div class="item">
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          <p>
            GV20 Therapeutics is a limited liability exempted company incorporated in 2016 with sites in Cambridge and Shanghai. The company utilizes high throughput functional genomics and AI approaches to identify novel cancer immunology drug targets, and discover and develop effective antibody drugs in cancer. GV20’s proprietary antibody drug discovery engine has helped establish a solid portfolio of immuno-oncology antibody drug candidates for cancer treatment. For more information, please visit the company’s website at <a href="http://gv20therapeutics.com/">http://gv20therapeutics.com/</a>.
          </p>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
